import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
import { Link } from 'gatsby'


import Tier2Layout from "../../components/content/Tier2Layout"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import PageSectionHeadline from "../../components/content/PageSectionHeadline"
import Subheading from "../../components/content/Subheading"
import NewsletterSubscriptionForm from '../../components/forms/NewsletterSubscriptionForm'; 

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../../styles/tier2.css'
import '../../styles/vacations.css'
import video_fallback from '../../images/vacations-hero-fallbackposter.jpg';
import preview_adventure from '../../images/vacations-preview-adventure-1.jpg';
import preview_lgbtq from '../../images/vacations-preview-lgbtq-1.jpg';
import preview_cruise from '../../images/vacations-preview-cruise-1.jpg';
import preview_spiritual from '../../images/vacations-preview-spiritual-1.jpg';
import preview_disney from '../../images/vacations-preview-disney-1.jpg';


const VacationsIndex = () => {
    const pageData = {
                        seo:{
                            title:'Vacation Ideas For 2022',
                            description:"The World is reopening. It's time for a change of pace. Start planning your winter vacation now. Go somewhere amazing in 2022. It's Time to Go Outward.",
                            image:'/images/vacations-hero-fallbackposter.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('VacationIdeas',location); 
    },[location]);

    const [showConsultationForm,setShowConsultationForm] = useState(false); 
    
    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier2Layout showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

        <div id="VacationIdeas_Hero">
            <div className="mast-seperator">
                <svg id="Tier2MastSeperatorSVG" data-name="Tier2MastSeperatorSVG" xmlns="http://www.w3.org/2000/svg" width="1320" height="96.8978" viewBox="0 0 1320 96.8978">
                <path class="background" d="M1320,51.7988H136.6818C1.0792,51.7988,0,96.8978,0,96.8978V-.3547H1320Z"/>
                <path class="middle-bar" d="M1320,38.7957H94.0934c-80.149,0-94.0934,20-94.0934,20V4.07s3.7773,20,92.9468,20H1320Z"/>
                <path class="upper-bar" d="M1.586,1.4134S17.79,20.75,95.2027,20.75C100.97,20.75,1320,21.0276,1320,21.0276V15.0245S114.4532,15.0121,95.8607,15.0121C20.0737,15.0121,1.586,1.4134,1.586,1.4134Z"/>
                <path class="lower-bar" d="M.0355,66.3013c9.1191-12.2588,35.4369-24.373,103.9433-24.2294,9.7635.02,1216.0212-.2762,1216.0212-.2762v6.0031s-1164.73,1.19-1183.3222,1.19C.2923,48.989,0,91.41,0,91.41Z"/>
                <polygon class="right-arrow" points="1315.675 48.499 1320 48.499 1320 10.898 1278.546 10.898 1315.675 48.499"/>
                </svg>
            </div>
            <div className="video">
                <video id="VacationIdeasBGVideo" autoplay muted loop>
                    <source src="https://storage.googleapis.com/outward-website-static-resources/videos/VacationsBGVid.mp4" type="video/mp4" />
                    <img src={video_fallback} alt="" />
                </video>
            </div>
            <div className="textcopy">
                <h1>The World is Reopening!</h1>
                <p><span>It’s time for a change of pace.</span> <span>Start planning your winter vacation now.</span> <span>Go somewhere amazing in 2022.</span></p>
                <p>Get your friends together and finally go on that trip you’ve been dreaming of since the pandemic started.</p>
                <p>It’s Time to Go Outward.</p>
            </div>
        </div>

        <Row>
            <Col md={8} className="Tier2MainText">
                <p>Wow, what a rough couple years it's been. Early 2020 was a scary time, with the world shutting down and most of us confined to our living rooms. We've missed
                    friends and family. We're working from home, or we're out on the front lines. We're going a little stir crazy.</p>
                <p>But the world is starting to carefully reopen. Hotels and Resorts are welcoming guests again. Flights are crossing the ocean. Cruise ships are sailing with 
                    fully vaccinated guests. Whether you travel for love, to escape the daily grind, or to push yourself to new levels, Outward Travel is ready to help you plan a safe 
                    and fulfilling experience. We keep an eye on your travel plans to help adjust with any changing conditions. We navigate the complex web of COVID-19 requirements. 
                    And if anything needs to change, we'll be there to help until you get home.</p>
                <p>Get vaccinated. Wear a mask. We'll help with everything else. Let's Travel Outward.</p>
            </Col>
            <Col md={4}>
                <NewsletterSubscriptionForm disposition="inline" format="vertical" color="blue">
                    <h6>Get Updates on New Travel Ideas</h6>
                    <p>Not ready to travel just yet? No worries! We're always on the lookout for new travel opportunities. 
                        Add your email to find out when we have new ideas to share:</p>
                </NewsletterSubscriptionForm>
            </Col>
        </Row>
        <PageSectionHeadline color="red" id="IdeasListSubHeading">Featured Vacation Ideas</PageSectionHeadline>
        <Row className="featured-ideas-grid-row">
            <Col sm={12} md={6}>
                <Link to="/vacations/adventure"><img src={preview_adventure} width="100%" alt="Adventure Vacations (Machu Picchu)" /></Link>
                <Subheading color="blue"><Link to="/vacations/adventure">Go on an Adventure</Link></Subheading>
                <p>When vacation means seeking out the new and the novel, you need an Adventure not just a vacation. 
                    This is a big, beautiful planet. There are places virtually untouched, unspoiled by mass tourism.
                    Go on a trek to ancient ruins, get to know the locals and experience a different way of life.</p>
                <p>Go on a <Link to="/vacations/adventure?view=safari">Safari</Link>, push your limits on a multi-day hike to <Link to="/vacations/adventure?view=peru">Machu Picchu in Peru</Link>, 
                    or reach the <Link to="/vacations/adventure?view=uncruise-alaska">Alaskan wilderness by sea</Link>.
                    Whatever your flavor, let's plan an adventure!</p>
            
                <ConsultationRequestTrigger color="blue" size="sm" 
                    text={<>Let's Talk About Adventure</>} 
                    onClick={()=>{startConsultationRequest('vacationsindex_adventure')}} />
            </Col>
            <Col sm={12} md={6}>
                <Link to="/vacations/lgbtq"><img src={preview_lgbtq} width="100%" alt="LGBTQ+ and Poly-Friendly Vacations" /></Link>
                <Subheading color="purple">LGBTQ+ & Poly-Friendly<span class="hidden-md"> Travel</span></Subheading>
                <p>We live differently, let's travel differently. Go solo &amp; meet new people, or 
                    take your family of choice on a long-overdue beach vacation. Whatever makes you whole, 
                    Outward Travel is your gay-owned travel partner for planning a safe and fulfilling 
                    vacation. 
                </p>
                <p>Did you know that <Link to="/vacations/lgbtq?view=puerto-rico">Puerto Rico is the LGBTQ+ Capital of the Caribbean</Link>? 
                Or if you need a trip as fabulous as you are, let's talk about a <Link to="/vacations/lgbtq?view=luxury-on-the-rails">Luxury train ride through Europe</Link>.
                </p>
            
                <ConsultationRequestTrigger color="purple" size="sm" 
                    text={<>Let's Travel Differently</>} 
                    onClick={()=>{startConsultationRequest('vacationsindex_lgbtq')}} />
            </Col>

        </Row>
        <Row className="featured-ideas-grid-row">
            <Col sm={12} md={6}>
                <Link to="/vacations/spiritual"><img src={preview_spiritual} width="100%" alt="Vacations for Spiritual Restoration" /></Link>
                <Subheading color="green"><Link to="/vacations/spiritual">Spiritual Restoration</Link></Subheading>
                <p>Has daily life become a slog? Are you losing motivation at work, or need to renew your 
                    committment to goals and dreams? A vacation is scientifically shown to improve health and 
                    reduce stress&mdash;tell us what you need, and we'll help you plan the right vacation for your renewal.
                </p>
                <p>You might need to relax and refresh in the <Link to="/vacations/spiritual?view=riviera-maya">Riviera Maya</Link>, 
                or experience a different way of life in the <Link to="/vacations/spiritual?view=india-sojourn">lush lands and charming villages of Northern India</Link>. 
                For those with a religious background, visit <Link to="/vacations/spiritual?view=ancient-sites-of-faith">visit ancient sites &amp; shrines of yoru faith</Link>.
                Return home refreshed and ready.</p>
            
                <ConsultationRequestTrigger color="green" size="sm" 
                    text={<>Let's Plan a Restorative Journey</>} 
                    onClick={()=>{startConsultationRequest('vacationsindex_spiritual')}} />
            </Col>
            <Col sm={12} md={6}>
                <Link to="/vacations/cruises"><img src={preview_cruise} width="100%" alt="Cruises for all Interests" /></Link>
                <Subheading color="yellow"><Link to="/vacations/cruises">Cruises for Every Interest</Link></Subheading>
                <p>Cruises are coming back, and they are an incredible bang for your buck. Whether you need 
                    a big ship with something for everyone in your family, or a gentle river cruise 
                    with your life-long friends, there is a ship and destination for everyone.
                </p>
                <p>Outward can book you on an all-vaccinated cruise with any of the cruise lines, from the big 
                    beautiful city-ships of <Link to="/vacations/cruises?view=carnival">Carnival</Link> and <Link to="/vacations/cruises?view=royal-caribbean">Royal Caribbean</Link>,
                    to the brand-new chic &amp; stylish <Link to="/vacations/cruises?view=virgin">Virgin Voyages</Link>.
                </p>
            
                <ConsultationRequestTrigger color="yellow" size="sm" 
                    text={<>Where do You Want to Go?</>} 
                    onClick={()=>{startConsultationRequest('vacationsindex_cruises')}} />
            </Col>

        </Row>
        <Row className="featured-ideas-grid-row">
            <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Link to="/vacations/disney"><img src={preview_disney} width="100%" alt="Walt Disney World" /></Link>
                <Subheading color="red"><Link to="/vacations/disney">Walt Disney World<sub><small>&reg;</small></sub></Link></Subheading>
                <p>From the wit and whimsey of the Magic Kingdom to a galaxy far, far away at the brand new <i>Star Wars&trade;:</i> Galaxy's Edge,
                <i>Walt Disney World</i> offers everyone&mdash;with or without kids&mdash; a magical escape. It's one of our 
                favorite places to visit, and we'll help match you with the right resort, reservations, and advice for visiting The Most Magical Place on Earth.
                </p>
                <p>And the year ahead is the perfect time visit with new experiences just around the corner. 
                    On October 1st, Walt Disney World kicks off its <Link to="/vacations/disney?view=50th-anniversary">50th Anniversary Celebration</Link>, with all-new  
                    extravaganzas. And next year, the first-of-its-kind <Link to="/vacations/disney?view=galactic-cruiser">2-night Galactic Starcruiser experience</Link>. 
                    And there's even more just around the corner!
                </p>            
                <ConsultationRequestTrigger color="red" size="sm" 
                    text={<>Let's Plan a Magical Escape</>} 
                    onClick={()=>{startConsultationRequest('vacationsindex_spiritual')}} />
            </Col>

        </Row>
        </Tier2Layout>
    </main>
  )
}

 
export default VacationsIndex;
